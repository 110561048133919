* {
  box-sizing: border-box;
  /* font-family: "Helvetica", sans-serif; */
}

.csv-input {
  padding: 10px;
  display: block;
  margin: 15px auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
}

table input {
  height: 2.5rem;
  border-radius: 0.375rem;
  border: 1px solid;
  border-color: inherit;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
}
